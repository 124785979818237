var minicart = (function () {
    var self = {
        setQuantity: function (quantity) {
            var elements = document.querySelectorAll('.header-minicart .cart-counter.badge, .cartLink .cart-counter');
            elements.forEach(function (element) {
                element.textContent = quantity;
                element.classList.toggle('d-none', quantity === 0);
            });
        },
        refreshQuantity: () => {
            const siteHeaderCart = document.querySelector("#siteHeader_cartRefresh");
            if (siteHeaderCart) {
                siteHeaderCart.setAttribute("data-refresh", true);
            }
        },
        loadQuantity: function () {
            if (!_THULEDATA?.isB2CEnabled)
                return;

            fetch('/api/sitecore/cart/GetTotalQuantity')
                .then((response) => response.json())
                .then((data) => self.setQuantity(data));
        },
        init: function () {
            if (document.querySelectorAll('.header-minicart').length > 0) {
                self.loadQuantity();
            }
        },
    };
    return self;
})();
minicart.init();
window.minicart = minicart;
